// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';

// import favicon here.
import './resources/images/favicon.png';

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
  }
}

(() => {
  // accordions' content when they have image is getting hidden as the height calculations are being done before the image gets loaded
  // re-calculating the height of panels when accordion is toggled, to make sure that the panels are shown without chopping off content and without any white spaces
  const handleAccordions = () => {
    const badgerInstanceKeys = Object.keys(window?._badgerInstances || {});

    if (badgerInstanceKeys?.length) {
      badgerInstanceKeys.forEach(key => {
        const inst = window._badgerInstances[key];
        inst?.calculateAllPanelsHeight?.();
      });
    }
  };

  const handleMobileMenu = () => {
    const brandMenuTrigger = document.querySelector(
      '#brand-menu-trigger'
    ) as HTMLButtonElement;
    const mobileMenuTrigger = document.querySelector(
      '#main-nav-trigger'
    ) as HTMLButtonElement;

    if (window.Bus && brandMenuTrigger && mobileMenuTrigger) {
      // when body is clicked close the brand menu
      const closeBrandNav = e => {
        const target = e.target || e.currentTarget;
        if (
          target &&
          !target.classList?.contains('header__brand-menu-trigger') &&
          !target.closest('.header__brand-menu-trigger')
        ) {
          e.stopImmediatePropagation();
          brandMenuTrigger.click();
        }
      };

      // when brand menu is opened, if mobile menu is open it must be closed
      // when brand menu is opened, mobile menu must be hidden
      // when brand menu is opened, if user clicks on body, brand menu must be closed
      window?.Bus?.on('emu-button:click', ({ id, toggleOn }) => {
        if (id === 'brand-menu-trigger') {
          if (toggleOn) {
            // closing mobile nav if it is open
            if (mobileMenuTrigger.classList.contains('js-toggle-on')) {
              mobileMenuTrigger.click();
            }
            document.body.classList.add('has-brand-menu-open');

            // when body is clicked close the brand menu
            document.body.addEventListener('click', closeBrandNav);
          } else {
            document.body.classList.remove('has-brand-menu-open');

            // removing body event listener
            document.body.removeEventListener('click', closeBrandNav);
          }
        }
      });
    }
  };

  // The video present inside the modal must stop playing when the modal is closed
  // Common Embed component has an option to do the mentioned functionality, but using that will give custom css to video controls. This site should show default Vimeo controls
  const handleModalsWithVideos = () => {
    if (window.Bus) {
      window.Bus.on(`emu-modal:close`, ({ id }) => {
        if (id) {
          const player = window?._plyrInstances?.[`${id}-embed`];
          player?.unload?.();
        }
      });
    }
  };

  const init = () => {
    handleMobileMenu();
    handleModalsWithVideos();

    // on page load, as there are images and fonts that are yet to load, the accordion's pre-calculated height cuts off the content. Re-calculating the size of the accordion panels on load and on resize
    window.addEventListener('load', handleAccordions);
    window.addEventListener('resize', handleAccordions);
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
